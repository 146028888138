import styled, { createGlobalStyle } from 'styled-components'

import { Media } from '@renderbus/common/theme'
import { Link } from 'gatsby'
import RoboBlackItalic from '../font/Robo-BlackItalic.ttf'
import RobotoBold from '../font/Roboto-Bold.ttf'
import RobotoRegular from '../font/Roboto-Regular.ttf'

export const Rc2022Style = createGlobalStyle`
  @font-face {
    font-family: 'Robo-BlackItalic';
    src: url(${RoboBlackItalic});
    font-style: normal;
    font-weight: normal;
    font-display: fallback;
  }
  @font-face {
    font-family: 'Roboto-Bold';
    src: url(${RobotoBold});
    font-style: normal;
    font-weight: normal;
    font-display: fallback;
  }
  @font-face {
    font-family: 'Roboto-Regular';
    src: url(${RobotoRegular});
    font-style: normal;
    font-weight: normal;
    font-display: fallback;
  }
  html {
    background-color: #070c45;
    ${Media.lessThan(Media.small)} {
      background-color: #f6f6f6;
    }
  }
  .hide {
    display: none !important;
  }
  .hideInMobile {
    ${Media.lessThan(Media.small)} {
      display: none !important;
    }
  }
  .hideInPC {
    ${Media.greaterThan(Media.small)} {
      display: none !important;
    }
  }
`

export const WorkBanner = styled.div`
  height: 400px;
  position: relative;
  ${Media.lessThan(Media.small)} {
    height: 160px;
    margin-top: 12vw;
  }
`

export const Navigation = styled.div`
  height: 72px;
  background: #070f2f;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 999;
  ${Media.lessThan(Media.small)} {
    height: 40px;
    position: relative;
    z-index: auto;
  }
`

export const CategoryItem = styled.div`
  width: 225px;
  height: 72px;
  background: ${p => p.active && `linear-gradient(90deg, #262B83 0%, #4147C1 100%)`};
  font-size: 18px;
  color: #d9d9d9;
  font-weight: 350;
  line-height: 72px;
  text-align: center;
  position: relative;
  transition: top 0.2s ease;
  &:hover {
    color: #6268ce;
  }
  ${Media.lessThan(Media.small)} {
    width: 93px;
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    &:hover {
      color: #d9d9d9;
    }
  }
`

export const SearchWork = styled.div`
  position: relative;
  input {
    width: 244px;
    height: 40px;
    background: #0d1847;
    box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.302);
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: none;
    font-size: 13px;
    color: #6171b6;
    padding-left: 16px;
    margin-left: 28px;
  }
  img {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 10px;
    top: 5px;
    bottom: 0;
  }
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`

export const SearchWorkMb = styled.div`
  display: none;
  position: relative;
  ${Media.lessThan(Media.small)} {
    display: flex;
    input {
      width: 100%;
      height: 38px;
      background: #ffffff;
      border-radius: 100px 100px 100px 100px;
      border: none;
      margin: 0 12px 16px;
      padding: 12px;
      font-size: 14px;
    }
    img {
      position: absolute;
      right: 20px;
      top: 5px;
    }
  }
`

export const PaginationWrapper = styled.div`
  margin: 76px auto 120px;
  button {
    width: 29px;
    height: 29px;
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    font-size: 14px;
    color: #9c9c9c;
    border: none;
    :hover {
      color: #000000;
      background: #ffffff;
      border: none;
    }
    &.active-btn {
      color: #000000;
    }
  }
`
export const ContestItem = styled(Link)`
  width: 386px;
  height: 321px;
  background: #ffffff;
  border-radius: 13px 13px 10px 10px;
  opacity: 1;
  position: relative;
  img {
    width: 386px;
    border-radius: 10px 10px 0px 0px;
  }
  .link-img {
    height: 217px;
  }
  &:hover {
    ::after {
      display: block;
      content: '';
      width: 386px;
      height: 217px;
      background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
      position: absolute;
      top: 0;
    }
  }
  ${Media.lessThan(Media.small)} {
    border-radius: 10px 10px 10px 10px;
    width: 45vw;
    height: 43vw;
    img {
      width: 100%;
      border-radius: 10px 10px 0px 0px;
    }
    &:hover {
      ::after {
        display: none;
      }
    }
  }
`
export const WorkContent = styled.div`
  max-width: 1200px;
  margin: auto;
  padding-top: 76px;
  ${Media.lessThan(Media.small)} {
    padding: 16px 0 24px;
    background: #f6f6f6;
    width: 100%;
  }
`
export const WorkHeader = styled.div`
  display: flex;
  color: #999999;
  line-height: 20px;
  font-size: 14px;
  justify-content: space-between;
  ${Media.lessThan(Media.small)} {
    font-size: 16px;
    font-weight: 500;
    color: #3b3b3b;
    line-height: 23px;
    width: 90vw;
    margin: auto;
  }
`
export const HeaderText = styled.span`
  color: ${p => p.isactive && '#6268CE'};
`
export const ContentList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 28px;
  ${Media.lessThan(Media.small)} {
    justify-content: center;
    gap: 13px;
    margin: 20px 12px 0;
  }
`
export const ContestText = styled.div`
  padding: 12px 24px 0;
  color: #3d3d3d;
  font-size: 18px;
  ${Media.lessThan(Media.small)} {
    font-size: 12px;
    padding: 8px 8px 0;
    font-weight: 700;
  }
`
export const WorkInfo = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 32px;
  align-items: center;
  font-size: 14px;
  margin-top: 10px;
  color: #999999;
  img {
    width: 18px;
    border-radius: 50%;
    opacity: 1;
    margin-right: 8px;
  }
  .avatar {
    width: 32px;
    height: 32px;
  }
  ${Media.lessThan(Media.small)} {
    margin-top: 8px;
    font-size: 10px;
    line-height: 14px;
    font-weight: 350;
    img {
      width: 14px;
      height: auto;
      border-radius: 50%;
      margin-right: 4px;
    }
    .avatar {
      width: 18px;
      height: 18px;
    }
    .avatorinfo {
      display: flex;
      max-width: 120px;
    }
  }
`

export const SeeMore = styled.div`
  width: 132px;
  height: 35px;
  line-height: 35px;
  border-radius: 2px 2px 2px 2px;
  border: 1px solid #757575;
  font-size: 12px;
  color: #747474;
  margin: 48px auto 0;
  display: flex;
  justify-content: center;
`

export const EmptyInfo = styled.div`
  text-align: center;
  margin-top: 124px;
  img {
    width: 178px;
  }
  .empty-text {
    font-size: 20px;
    color: #ffffff;
    margin-top: 20px;
  }
  ${Media.lessThan(Media.small)} {
    .empty-text {
      font-size: 14px;
      color: #5e5e5e;
      line-height: 26px;
      margin-top: 12px;
    }
  }
`
