/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { useLocation } from '@reach/router'
import Helmet from 'react-helmet'

import { SEO, Billboard, Pagination } from '@renderbus/common/components'
import axios from 'axios'

import {
  RCHeader,
  HeaderLogo,
  HeaderItem,
  SignButton,
  NavigateMenuIcon,
  NavigateMenu,
  NavigateMenuItem,
  PageFooter,
} from './rc.atom'
import {
  Rc2022Style,
  WorkBanner,
  Navigation,
  CategoryItem,
  SearchWork,
  SearchWorkMb,
  PaginationWrapper,
  ContestItem,
  WorkHeader,
  WorkContent,
  HeaderText,
  ContentList,
  ContestText,
  WorkInfo,
  SeeMore,
  EmptyInfo,
} from './rc2022.atom'
import SearchIcon from '../images/render-contest-2023/search-icon.png'
import Avatar from '../images/render-contest-2023/avatar.png'
import EyesIcon from '../images/render-contest-2023/eyes-icon.png'
import EmptyWork from '../images/render-contest-2023/work-empty.png'
import EmptyWorkMb from '../images/render-contest-2023/work-empty-mb.png'
import { LocationService } from '@renderbus/common/service'
import PoliceIcon from '../../../common/components/footer/images/police.png'
import { allRcWorkList } from '../constant/rc-result'

function Rc2022() {
  const headerItemList = [
    {
      label: '赛事详情',
      link: '/rc.html#rc-time',
      left: '15.47vw',
    },
    {
      label: '赛事资料',
      link: '/rc.html#rc-content',
      left: '4.79vw',
    },
    {
      label: '常见问题',
      link: '/rc.html#rc-qa',
      left: '5.15vw',
    },
    {
      label: '首届回顾',
      link: 'https://www.rayvision.com/rrc.html',
      rel: 'nofollow',
      target: '_blank',
      left: '5.21vw',
    },
  ]
  const mobileMenuItemList = [
    {
      label: '赛果公布',
      link: '/rc.html#rc-result',
    },
    {
      label: '大赛引言',
      link: '/rc.html#rc-words',
    },
    {
      label: '大赛时间',
      link: '/rc.html#rc-time',
    },
    {
      label: '大赛组别',
      link: '/rc.html#rc-group',
    },
    {
      label: '大赛奖品',
      link: '/rc.html#rc-award',
    },
    {
      label: '评委阵容',
      link: '/rc.html#rc-judges',
    },
    {
      label: '大赛内容',
      link: '/rc.html#rc-content',
    },
    {
      label: '设计说明',
      link: '/rc.html#rc-design',
    },
    {
      label: '常见问题',
      link: '/rc.html#rc-qa',
    },
    {
      label: '返回顶部',
    },
  ]
  const WorkCategoryList = [
    { label: '全部作品', type: 'all' },
    { label: '入围作品', type: 'sNominated' },
    { label: '专业组赛道', type: 'major' },
    { label: '学生组赛道', type: 'student' },
  ]

  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [searchName, setSearchName] = useState()
  const [workList, setWorkList] = useState([])
  const [workNum, setWorkNum] = useState(0)
  const [sortType, setSortType] = useState(0) // 0 默认按表格顺序， 1 最新 ， 2 浏览量
  const [pageSize, setPageSize] = useState(15)
  const [pageNum, setPageNum] = useState(1)
  const [category, setCategory] = useState(0)
  const [isNominated, setIsNominated] = useState(null) // 1入围 0 未入围
  const [group, setGroup] = useState(null) // 1是专业组 0学生组
  const location = useLocation()
  const [allWorkList, setAllWorkList] = useState([])
  const [workListMb, setWorkListMb] = useState([])

  function handleSearch() {
    setPageNum(1)
    initData()
  }
  function handleSearchMb() {
    initDataMb()
  }
  function setWorkCategory(item, index) {
    setCategory(index)
    setPageNum(1)
    setSearchName('')
    const currentLocationUrl = LocationService.updateLocationSearchString({ page: 1 })
    navigate(currentLocationUrl, { state: { pageNum: 1 } })
    switch (item.type) {
      case 'sNominated':
        setIsNominated(1)
        setGroup(null)
        break
      case 'major':
        setIsNominated(null)
        setGroup(1)
        break
      case 'student':
        setIsNominated(null)
        setGroup(0)
        break
      default:
        setIsNominated(null)
        setGroup(null)
        break
    }
  }
  function initData() {
    let params = {
      sortType: sortType,
      pageSize: pageSize,
      pageNum: pageNum,
    }
    if (isNominated) {
      params.isNominated = 1
    }
    if (group !== null) {
      params.group = group
    }
    if (searchName) {
      params.searchName = searchName
    }
    axios({
      method: 'post',
      url: 'https://www.renderbus.com/rc-result/view-count',
      data: {
        ...params,
      },
    })
      .then((response) => {
        const { data } = response
        if (data) {
          const idList = data.data.map((i) => i.id)
          const filteredData = allRcWorkList
            .filter((item) => idList.includes(item.id))
            .sort(
              (a, b) =>
                data.data.findIndex((obj) => obj.id === a.id) -
                data.data.findIndex((obj) => obj.id === b.id),
            )
          const filterWorkList = filteredData.map((item) => {
            const correspondingItem = data.data.find((obj) => obj.id === item.id)
            return { ...item, viewCount: correspondingItem.viewCount }
          })
          setWorkList(filterWorkList)
          setWorkNum(data.total)
        } else {
          setWorkList([])
          return
        }
      })
      .catch((err) => {
        console.log(err)
        return
      })
      .finally(() => {
        return
      })
  }
  function initDataMb() {
    let params = {
      sortType: 0,
    }
    if (isNominated) {
      params.isNominated = 1
    }
    if (group !== null) {
      params.group = group
    }
    if (searchName) {
      params.searchName = searchName
    }
    axios({
      method: 'post',
      url: 'https://www.renderbus.com/rc-result/view-count',
      data: {
        ...params,
      },
    })
      .then((response) => {
        const { data } = response
        if (data) {
          const idList = data.data.map((i) => i.id)
          const filteredData = allRcWorkList
            .filter((item) => idList.includes(item.id))
            .sort(
              (a, b) =>
                data.data.findIndex((obj) => obj.id === a.id) -
                data.data.findIndex((obj) => obj.id === b.id),
            )
          const filterWorkList = filteredData.map((item) => {
            const correspondingItem = data.data.find((obj) => obj.id === item.id)
            return { ...item, viewCount: correspondingItem.viewCount }
          })
          if (data.total > 14) {
            setWorkListMb(filterWorkList.slice(0, 14))
            setAllWorkList(filterWorkList.slice(14))
          } else {
            setWorkListMb(filterWorkList)
            setAllWorkList([])
          }
          setWorkNum(data.total)
        } else {
          return
        }
      })
      .catch((err) => {
        console.log(err)
        return
      })
  }
  function handleSortType(type) {
    setPageNum(1)
    setPageSize(15)
    if (sortType === type) {
      setSortType(0)
    } else {
      setSortType(type)
    }
  }
  function seeMore() {
    let list = [...workListMb, ...allWorkList.slice(0, 14)]
    setWorkListMb(list)
    setAllWorkList(allWorkList.slice(14))
  }
  function handleCurrentPage() {
    console.log(pageNum)
  }
  function handleScroll() {
    document.body.scrollTop = document.documentElement.scrollTop = 0
  }
  useEffect(() => {
    initData()
    initDataMb()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortType, isNominated, group, pageNum])

  useEffect(() => {
    if (location.search) {
      let current = location.search.replace('?page=', '')
      setPageNum(Number(current))
    }
  }, [location.search])

  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { regex: "render-contest-2023/rc2022_bg.png/" }) {
        ...fluidImage
      }
      bannerMb: file(relativePath: { regex: "render-contest-2023/rc2022_bg_mb.png/" }) {
        ...fluidImage
      }
    }
  `)
  return (
    <React.Fragment>
      <Helmet>
        <script>
          {`
              var _hmt = _hmt || [];
              (function() {
                var hm = document.createElement("script");
                hm.src = "https://hm.baidu.com/hm.js?695fad530c8ccd7ba407ec0f01da7817";
                var s = document.getElementsByTagName("script")[0];
                s.parentNode.insertBefore(hm, s);
              })();
            `}
        </script>
      </Helmet>
      <SEO
        title='参赛作品展 - 第二届3D渲染动画创作大赛｜算联视界,元生万象'
        keywords='渲染大赛, 瑞云渲染大赛,瑞云渲染动画创作大赛'
        description='由瑞云科技与贵安新区科创产业发展有限公司联合主办的第二届3D渲染动画创作大赛-算联视界,元生万象已圆满结束,众多优秀作品展示,欢迎查看。'
        shareImgSrc='//rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/assets/banner.png'
        sharePostSlug='rc2022.html'
      />
      <RCHeader>
        <HeaderLogo />
        {headerItemList.map((item) => (
          <HeaderItem
            key={item.label}
            href={item.link}
            rel={item.rel}
            target={item.target}
            left={item.left}
          >
            {item.label}
          </HeaderItem>
        ))}
        <SignButton href='/rc2022/' id='rc-entrylist'>
          参赛作品展
        </SignButton>
        <NavigateMenuIcon
          isActive={!showMobileMenu}
          onClick={() => setShowMobileMenu(!showMobileMenu)}
        />
        <NavigateMenu isActive={showMobileMenu}>
          {mobileMenuItemList.map((item) =>
            item.link ? (
              <NavigateMenuItem href={item.link} key={item.label}>
                {item.label}
              </NavigateMenuItem>
            ) : (
              <NavigateMenuItem
                key={item.label}
                onClick={() => {
                  if (!item.link) {
                    handleScroll()
                  }
                }}
              >
                {item.label}
              </NavigateMenuItem>
            ),
          )}
        </NavigateMenu>
      </RCHeader>
      <WorkBanner>
        <Billboard
          fluid={[
            data.bannerMb.childImageSharp.fluid,
            {
              ...data.banner.childImageSharp.fluid,
              media: `(min-width: 780px)`,
            },
          ]}
        />
      </WorkBanner>
      <Navigation>
        {WorkCategoryList.map((item, index) => (
          <CategoryItem
            className={`category-item-${index}`}
            key={index}
            onClick={() => setWorkCategory(item, index)}
            active={category === index}
          >
            {item.label}
          </CategoryItem>
        ))}
        <SearchWork>
          <input
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
            onKeyPress={() => handleSearch()}
            placeholder='请输入选手名称或作品名称'
          />
          <img src={SearchIcon} onClick={() => handleSearch()} alt='' />
        </SearchWork>
      </Navigation>
      <WorkContent>
        {workList.length > 0 ? (
          <>
            <WorkHeader className='hideInMobile'>
              <span>作品总数：{workNum}</span>
              <span>
                <HeaderText onClick={() => handleSortType(1)} isactive={sortType === 1}>
                  最新
                </HeaderText>
                &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                <HeaderText onClick={() => handleSortType(2)} isactive={sortType === 2}>
                  浏览
                </HeaderText>
              </span>
            </WorkHeader>
            <ContentList className='hideInMobile'>
              {workList.map((item, index) => {
                return (
                  <ContestItem key={`pc_${index}`} to={`rc2022/details-id-${item.id}.html`}>
                    <img
                      src={`${item.link}?x-oss-process=video/snapshot,t_1000,f_jpg,m_fast,w_386,h_217`}
                      alt=''
                      className='link-img'
                    />
                    {/* <video src={`${item.link}`} preload='true' autoPlay muted controls></video> */}
                    <ContestText>
                      <div>{item.workName}</div>
                      <WorkInfo>
                        <div>
                          <img className='avatar' src={Avatar} alt='' />
                          {item.name}
                        </div>
                        <div>
                          <img src={EyesIcon} alt='' />
                          {item.viewCount}
                        </div>
                      </WorkInfo>
                    </ContestText>
                  </ContestItem>
                )
              })}
            </ContentList>
            <PaginationWrapper className='hideInMobile'>
              <Pagination
                currentPage={pageNum}
                total={Math.ceil(workNum / 15)}
                handleCurrentPage={() => handleCurrentPage()}
              />
            </PaginationWrapper>
          </>
        ) : (
          <EmptyInfo className='hideInMobile'>
            <img src={EmptyWork} alt='' />
            <div className='empty-text'>没有找到相关内容，请尝试使用其他词汇搜索</div>
          </EmptyInfo>
        )}
        <SearchWorkMb className='hideInPC'>
          <input
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
            onKeyPress={() => handleSearchMb()}
            placeholder='请输入选手名称或作品名称'
          />
          <img src={SearchIcon} onClick={() => handleSearchMb()} alt='' />
        </SearchWorkMb>
        {workListMb.length > 0 ? (
          <>
            <WorkHeader className='hideInPC'>
              <span>全部作品（{workNum}）</span>
            </WorkHeader>
            <ContentList className='hideInPC'>
              {workListMb &&
                workListMb.map((item, index) => {
                  return (
                    <ContestItem key={`mb_${index}`} to={`rc2022/details-id-${item.id}.html`}>
                      <img
                        style={{ minHeight: '50%' }}
                        src={`${item.link}?x-oss-process=video/snapshot,t_10000,f_jpg,m_fast,w_386,h_217`}
                        alt=''
                      />
                      <ContestText>
                        <div>{item.workName}</div>
                        <WorkInfo>
                          <div className='avatorinfo'>
                            <img className='avatar' src={Avatar} alt='' />
                            <div>{item.name}</div>
                          </div>
                          <div>
                            <img src={EyesIcon} alt='' />
                            {item.viewCount}
                          </div>
                        </WorkInfo>
                      </ContestText>
                    </ContestItem>
                  )
                })}
            </ContentList>
            {allWorkList.length > 0 && (
              <SeeMore className='hideInPC' onClick={() => seeMore()}>
                查看更多
              </SeeMore>
            )}
          </>
        ) : (
          <EmptyInfo className='hideInPC'>
            <img src={EmptyWorkMb} alt='' />
            <div className='empty-text'>
              没有找到相关内容
              <br />
              请尝试使用其他词汇搜索
            </div>
          </EmptyInfo>
        )}
      </WorkContent>
      <PageFooter>
        <p>
          <img src={PoliceIcon} alt='' />© 2022
          <a href='https://www.rayvision.com/' rel='nofollow'>
            深圳市瑞云科技股份有限公司
          </a>
          <a href='https://beian.miit.gov.cn/#/Integrated/index' target='_blank' rel='nofollow'>
            粤ICP备12028569号
          </a>
        </p>
        <p>中华人民共和国增值电信业务经营许可证编号：合字 B1-20200125</p>
      </PageFooter>
      <Rc2022Style />
    </React.Fragment>
  )
}

export default Rc2022
